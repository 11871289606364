
import Vue from 'vue'
import { IExtraLink } from '../store/state'

interface ExtraLinksMethods {
}

interface ExtraLinksComputed {
    extraLinks: string
}

interface ExtraLinksProps {	
}

export default Vue.extend<{}, ExtraLinksMethods, ExtraLinksComputed, ExtraLinksProps>({
    name: 'ExtraLinks', 
    computed: {
        extraLinks() {
            return this.$store.getters.extraLinks;
        }
	},
	components: {
		
	}
})
