
import Vue from 'vue'
import { IMenuItem } from './store/state'
import Menu from './components/Menu.vue'

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	menuItems: IMenuItem[]
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	components: {
		Menu
	},
	computed: {
		menuItems() {
			return this.$store.getters.menuItems;
		},
	}
})
