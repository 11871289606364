
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import MenuItem from './MenuItem.vue'
import { ITranslations, ICampaignWeb } from '../store/state'
import ExtraLinks from './ExtraLinks.vue'
import TopLinks from './TopLinks.vue'

interface MenuData {
    visible: boolean;
	mobile: boolean;
}
interface MenuMethods {
    toggleMenu(): void
}
interface MenuComputed {
    menuItems: any
    translations: ITranslations,
    className: string,
    campaignWeb: ICampaignWeb
}

interface MenuProps {
    menuItems: IMenuItem[]
}

const component: Function = Vue.extend<MenuData, MenuMethods, MenuComputed, MenuProps>({
    name: 'Menu',
    data() {
        return { 
            visible: false,
            mobile: false
        }
    }, 
    created() {
		this.mobile = window.innerWidth <= 1199;
    },   
    computed: {
        menuItems() {
            return this.$store.getters.menuItems;
        },
        translations() {
			return this.$store.getters.translations
		},
        className() {
            return this.$store.getters.className;
        },
        campaignWeb() {
            return this.$store.getters.campaignWeb;
        },
	},
    methods: {
        toggleMenu() {
            this.visible = !this.visible;
            const body = document.querySelector("body");
            body ? body.classList.toggle("fixed") : null;
        }
    },
    components: {
		MenuItem,
        ExtraLinks,
        TopLinks
	},
})

export default component
